import React from "react";

const Footer = () => {
  return (
    <footer style={styles.footer}>
      <div style={styles.contentWrapper}>
        <p style={styles.text}>2024 LOWPRICETRAVELS. All rights reserved.</p>
        <p style={styles.text}>Email: cooper@tintechsolutions.com</p>
      </div>
    </footer>
  );
};

const styles = {
  footer: {
    backgroundColor: "#FE737B",
    color: "white",
    padding: "20px 5%",
    width: "100%",
    boxSizing: "border-box",
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "10px",
  },
  text: {
    margin: 0,
    fontSize: "14px",
  },
  "@media (max-width: 768px)": {
    contentWrapper: {
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
    text: {
      fontSize: "12px",
    },
  },
};

// Apply media queries
if (typeof window !== "undefined") {
  if (window.matchMedia("(max-width: 768px)").matches) {
    Object.assign(
      styles.contentWrapper,
      styles["@media (max-width: 768px)"].contentWrapper
    );
    Object.assign(styles.text, styles["@media (max-width: 768px)"].text);
  }
}

export default Footer;
