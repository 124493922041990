import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyD75zuCahrAS2BXjsvedUsr-Y5ihQxckDM",
  authDomain: "lowpricetravels-react.firebaseapp.com",
  projectId: "lowpricetravels-react",
  storageBucket: "lowpricetravels-react.appspot.com",
  messagingSenderId: "241750144115",
  appId: "1:241750144115:web:cf3e868661aca94494a606",
  measurementId: "G-TR1ZN1L33W",
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

const storage = getStorage(app);

export { db, storage };
