import React from "react";
import { Link } from "react-router-dom";
import { ArrowRight } from "lucide-react";

const CreateItineraryPrompt = () => {
  const styles = {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "20px",
      borderRadius: "10px",
      margin: "20px 0",
    },
    link: {
      display: "flex",
      alignItems: "center",
      backgroundColor: "#59a7c4",
      color: "white",
      border: "none",
      padding: "15px 30px",
      fontSize: "1.2rem",
      fontWeight: "bold",
      borderRadius: "50px",
      cursor: "pointer",
      transition: "all 0.3s ease",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
      textDecoration: "none",
    },
    linkHover: {
      backgroundColor: "#4a8ba8",
      transform: "translateY(-2px)",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    },
    linkText: {
      marginRight: "10px",
    },
    arrowIcon: {
      transition: "transform 0.3s ease",
    },
    arrowIconHover: {
      transform: "translateX(5px)",
    },
  };

  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <div style={styles.container}>
      <Link
        to="/create-itinerary"
        style={{
          ...styles.link,
          ...(isHovered ? styles.linkHover : {}),
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <span style={styles.linkText}>Create an Itinerary for your trip</span>
        <ArrowRight
          style={{
            ...styles.arrowIcon,
            ...(isHovered ? styles.arrowIconHover : {}),
          }}
          size={24}
        />
      </Link>
    </div>
  );
};

export default CreateItineraryPrompt;
