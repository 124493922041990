import React, { useEffect, useState } from "react";
import FlightSearch from "./flightSearchComponent";
import TripsList from "./tripsList";
import InfluencerTravelSection from "./influencerTravelSection";
import CreateItineraryPrompt from "./createItineraryPrompt";
import getUsers from "./getUsers";
import InfluencerCarousel from "./influencerCarousel";
import Spinner from "./spinner";

const HomePage = () => {
  const [flightSearchDetails, setFlightSearchDetails] = useState([]);
  const [departureLocation, setDepartureLocation] = useState("");
  const [arrivalLocation, setArrivalLocation] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [noResultsError, setNoResultsError] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getUsers();
      setUsers(data);
    };

    fetchData();
  }, []);

  return (
    <div>
      <header style={styles.header}>
        <FlightSearch
          searchDetails={flightSearchDetails}
          setSearchDetails={setFlightSearchDetails}
          departureLocation={departureLocation}
          setDepartureLocation={setDepartureLocation}
          arrivalLocation={arrivalLocation}
          setArrivalLocation={setArrivalLocation}
          selectedMonth={selectedMonth}
          setSelectedMonth={setSelectedMonth}
          setAdmin={setAdmin}
          setNoResultsError={setNoResultsError}
        />
      </header>
      <main style={styles.mainContent}>
        {flightSearchDetails.length ? (
          <div className="trip-card-container">
            <h2>
              Our AI found the cheapest times to fly from{" "}
              <span style={{ fontSize: "28px", color: "#FE737B" }}>
                {departureLocation}
              </span>{" "}
              to{" "}
              <span style={{ fontSize: "28px", color: "#FE737B" }}>
                {arrivalLocation}
              </span>{" "}
              in{" "}
              <span style={{ fontSize: "28px", color: "#FE737B" }}>
                {selectedMonth}
              </span>{" "}
              are:
            </h2>
            <p style={{ color: "#FE737B" }}>
              ** Flight prices are sometimes inaccurate due to initial loads on
              certain sites **
            </p>
            <TripsList
              flightSearchDetails={flightSearchDetails}
              admin={admin}
            />
          </div>
        ) : null}
        {noResultsError ? (
          <>
            <p
              style={{
                color: "#FE737B",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              ** Our apologies, we couldn't find any flights with the locations
              and month you specified. Try again with new inputs. Thank you! **
            </p>
          </>
        ) : null}
        <section>
          <CreateItineraryPrompt />
        </section>
        <section>
          {users.length > 0 ? (
            <InfluencerCarousel influencers={users} />
          ) : (
            <Spinner />
          )}
        </section>
        <section>
          <InfluencerTravelSection />
        </section>
      </main>
    </div>
  );
};

const styles = {
  header: {
    textAlign: "center",
    backgroundColor: "#f8f8f8",
  },
  mainContent: {
    padding: "20px",
    textAlign: "center",
  },
};

export default HomePage;
