import React, { useEffect, useMemo, useState, useRef } from "react";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { collection, addDoc, getDocs } from "firebase/firestore";
import { storage, db } from "./db";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { ArrowLeft } from "lucide-react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripePaymentForm from "./stripePayment";
import InfluencerShowcase from "./influencerShowcase";
import SuccessComponent from "./success";
import { BounceLoader } from "react-spinners";

// Prod
const stripePromise = loadStripe(
  "pk_live_51OxM3mIGI0E0PG2jz4rGRjyDThtxzGOnIED4XbgzsF5tWPlKyEaFN3uxRCx6XnVpNOwhhNmR4nHg2WK98ocUnE6M00cDjdjSft"
);

// Test
// const stripePromise = loadStripe(
//   "pk_test_51OxM3mIGI0E0PG2j5jfi9QzRteoVNFAznKUyFGfneMpf58cYhUQbc2WnNz7Ax868lAuthv59Jz7G4GCQUd4zUt1Y00lux9u1EC"
// );

const localizer = momentLocalizer(moment);

const ContentCreatorSignup = () => {
  const [formData, setFormData] = useState({
    instagramHandle: "",
    creatorName: "",
    tiktokHandle: "",
    bio: "",
    website: "",
    instagramLink: "",
    tiktokLink: "",
    facebookLink: "",
    xLink: "",
    profileImage: null,
    featuredImages: [],
    aviasalesLink: "",
    bookingLink: "",
    getyourguideLink: "",
    displayMonth: "",
    displayDays: 0,
    selectedDates: [],
  });

  const [totalCost, setTotalCost] = useState(0);
  const [progress, setProgress] = useState(0);
  const [occupiedDates, setOccupiedDates] = useState([]);
  const [showPayment, setShowPayment] = useState(false);
  const [profileImageTempUrl, setProfileImageTempUrl] = useState("");
  const [featuredImageTempUrls, setFeaturedImageTempUrls] = useState([]);
  const [fileData, setFileData] = useState({
    profileImage: null,
    featuredImages: [],
  });
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const showcaseRef = useRef(null);

  useEffect(() => {
    const currentDate = new Date();
    const sampleOccupiedDates = [
      new Date(currentDate.getFullYear(), currentDate.getMonth(), 5),
      new Date(currentDate.getFullYear(), currentDate.getMonth(), 10),
      new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
      new Date(currentDate.getFullYear(), currentDate.getMonth(), 20),
      new Date(currentDate.getFullYear(), currentDate.getMonth(), 25),
    ];
    setOccupiedDates(sampleOccupiedDates);
  }, []);

  useEffect(() => {
    fetchOccupiedDates();
  }, []);

  useEffect(() => {
    setTotalCost(formData.selectedDates.length * 3); // Calculate cost based on selected dates
  }, [formData.selectedDates]);

  const fetchOccupiedDates = async () => {
    try {
      const usersRef = collection(db, "users");
      const querySnapshot = await getDocs(usersRef);
      let dates = [];
      querySnapshot.forEach((doc) => {
        const userData = doc.data();
        if (userData.selectedDates && Array.isArray(userData.selectedDates)) {
          const userDates = userData.selectedDates.map((date) => {
            // Convert Firestore timestamp to JavaScript Date
            return new Date(date.seconds * 1000);
          });
          dates = [...dates, ...userDates];
        }
      });

      // Remove duplicate dates
      const uniqueDates = [...new Set(dates.map((date) => date.getTime()))].map(
        (time) => new Date(time)
      );

      setOccupiedDates(uniqueDates);
    } catch (error) {
      console.error("Error fetching occupied dates:", error);
    }
  };

  const isDateSelectable = (date) => {
    const today = moment().startOf("day");
    return moment(date).isAfter(today) && !isDateOccupied(date);
  };

  const handleDateSelect = (slotInfo) => {
    const selectedDate = moment(slotInfo.start).startOf("day").toDate();

    if (!isDateSelectable(selectedDate)) {
      return;
    }

    setFormData((prevState) => {
      const dateAlreadySelected = prevState.selectedDates.some((date) =>
        moment(date).isSame(selectedDate, "day")
      );

      if (dateAlreadySelected) {
        const newSelectedDates = prevState.selectedDates.filter(
          (date) => !moment(date).isSame(selectedDate, "day")
        );
        return { ...prevState, selectedDates: newSelectedDates };
      } else {
        return {
          ...prevState,
          selectedDates: [...prevState.selectedDates, selectedDate],
        };
      }
    });
  };

  const isDateOccupied = (date) => {
    return occupiedDates.some((occupiedDate) =>
      moment(occupiedDate).isSame(date, "day")
    );
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    const style = {
      backgroundColor: event.title === "BOOKED" ? "#f0f0f0" : "#3174ad",
      color: event.title === "BOOKED" ? "black" : "white",
      border: "0px",
      display: "block",
    };
    return { style };
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;

    setFormData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  const handleImageUpload = (e, type) => {
    const files = e.target.files;
    if (type === "profile") {
      const file = files[0];
      setFileData((prev) => ({ ...prev, profileImage: file }));
      setFormData((prev) => ({ ...prev, profileImage: file }));
      setProfileImageTempUrl(URL.createObjectURL(file));
    } else if (type === "featured") {
      const newFiles = Array.from(files);
      if (newFiles.length !== 3) {
        alert("Please select exactly 3 featured images.");
        setFileData((prev) => ({
          ...prev,
          featuredImages: [],
        }));
        setFormData((prev) => ({
          ...prev,
          featuredImages: [],
        }));
        return;
      }
      setFileData((prev) => ({
        ...prev,
        featuredImages: newFiles,
      }));
      setFormData((prev) => ({
        ...prev,
        featuredImages: newFiles,
      }));
      const newUrls = newFiles.map((file) => URL.createObjectURL(file));
      setFeaturedImageTempUrls(newUrls);
    }
  };

  const calendarEvents = useMemo(() => {
    return [
      ...occupiedDates.map((date) => ({
        start: new Date(date),
        end: new Date(date),
        title: "BOOKED",
      })),
      ...formData.selectedDates.map((date) => ({
        start: new Date(date),
        end: new Date(date),
        title: formData.creatorName,
      })),
    ];
  }, [occupiedDates, formData.selectedDates, formData.creatorName]);

  useEffect(() => {
    setTotalCost(formData.displayDays * 3); // Calculate cost based on display days
  }, [formData.displayDays]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!formData.profileImage || formData.featuredImages.length !== 3) {
      alert("Please upload a profile image and exactly 3 featured images");
      setIsLoading(false);
      return;
    }

    if (formData.selectedDates.length === 0) {
      alert("Please select at least one date for display");
      setIsLoading(false);
      return;
    }

    const cleanedFormData = {
      ...formData,
      instagramHandle: formData.instagramHandle.replace(/[@\s]/g, ""),
      tiktokHandle: formData.tiktokHandle.replace(/[@\s]/g, ""),
    };

    // Validate other required fields
    const requiredFields = [
      "creatorName",
      "instagramHandle",
      "instagramLink",
      "aviasalesLink",
      "bookingLink",
      "getyourguideLink",
    ];

    for (const field of requiredFields) {
      if (!cleanedFormData[field]) {
        alert(
          `Please fill in the ${field
            .replace(/([A-Z])/g, " $1")
            .toLowerCase()} field`
        );
        return;
      }
    }

    setShowPayment(true);
    setIsLoading(false);

    setTimeout(() => {
      if (showcaseRef.current) {
        showcaseRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 100);
  };

  const handlePaymentSuccess = async () => {
    setIsLoading(true);
    try {
      // Upload the profile image to Firebase Storage
      const profileImageRef = ref(
        storage,
        `profileImages/${formData.profileImage.name}`
      );
      const profileImageTask = uploadBytesResumable(
        profileImageRef,
        formData.profileImage
      );

      let profileImageUrl = "";
      let featuredImageUrls = [];

      await new Promise((resolve, reject) => {
        profileImageTask.on(
          "state_changed",
          (snapshot) => {
            const progressPercent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setProgress(progressPercent);
          },
          (error) => {
            console.error("Error uploading profile image:", error);
            reject(error);
          },
          async () => {
            profileImageUrl = await getDownloadURL(
              profileImageTask.snapshot.ref
            );
            resolve();
          }
        );
      });

      // Upload featured images
      featuredImageUrls = await Promise.all(
        formData.featuredImages.map(async (image) => {
          const imageRef = ref(storage, `featuredImages/${image.name}`);
          const uploadTask = uploadBytesResumable(imageRef, image);
          await uploadTask;
          return getDownloadURL(imageRef);
        })
      );

      // Save all user data to Firestore
      await addDoc(collection(db, "users"), {
        creatorName: formData.creatorName,
        instagramHandle: formData.instagramHandle,
        tiktokHandle: formData.tiktokHandle,
        bio: formData.bio,
        website: formData.website,
        instagramLink: formData.instagramLink,
        tiktokLink: formData.tiktokLink,
        facebookLink: formData.facebookLink,
        xLink: formData.xLink,
        aviasalesLink: formData.aviasalesLink,
        bookingLink: formData.bookingLink,
        getyourguideLink: formData.getyourguideLink,
        selectedDates: formData.selectedDates,
        profileImage: profileImageUrl,
        featuredImages: featuredImageUrls,
        totalCost,
        createdAt: new Date(),
      });

      setIsSuccess(true);

      setTotalCost(0);
      setProgress(0);
      setShowPayment(false);
    } catch (error) {
      console.error("Error creating user:", error);
      alert("There was an error creating your profile. Please try again.");
    }
    setIsLoading(false);
  };

  const handleBackToForm = () => {
    // Recreate File objects from the stored data
    if (fileData.profileImage) {
      const profileFile = new File(
        [fileData.profileImage],
        fileData.profileImage.name,
        {
          type: fileData.profileImage.type,
        }
      );
      setFormData((prev) => ({ ...prev, profileImage: profileFile }));
    }

    const featuredFiles = fileData.featuredImages.map(
      (file) => new File([file], file.name, { type: file.type })
    );
    setFormData((prev) => ({ ...prev, featuredImages: featuredFiles }));

    setShowPayment(false);
  };

  if (isSuccess) {
    return (
      <SuccessComponent
        creatorName={formData.creatorName}
        selectedDates={formData.selectedDates}
      />
    );
  }

  return (
    <>
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
          }}
        >
          <BounceLoader color="#ffffff" size={60} />
        </div>
      )}
      {!showPayment ? (
        <div className="content-creator-signup">
          <h2>Sign Up as a Content Creator</h2>

          <form onSubmit={handleSubmit}>
            <div className="form-section">
              <h3>Basic Information</h3>
              <input
                type="text"
                name="creatorName"
                value={formData.creatorName}
                onChange={handleInputChange}
                placeholder="Content Creator Name"
                className="ccp-input ccp-input-with-prefix"
                required
              />
              <div className="ccp-input-group">
                <div className="ccp-input-prefix">@</div>
                <input
                  type="text"
                  name="instagramHandle"
                  value={formData.instagramHandle}
                  onChange={handleInputChange}
                  placeholder="Instagram Handle"
                  className="ccp-input ccp-input-with-prefix"
                  required
                />
              </div>

              <div className="ccp-input-group">
                <div className="ccp-input-prefix">@</div>
                <input
                  type="text"
                  name="tiktokHandle"
                  value={formData.tiktokHandle}
                  onChange={handleInputChange}
                  placeholder="TikTok Handle (optional)"
                  className="ccp-input ccp-input-with-prefix"
                />
              </div>

              <textarea
                name="bio"
                value={formData.bio}
                onChange={handleInputChange}
                placeholder="Short Bio (up to 200 characters)"
                maxLength={200}
                required
                className="ccp-input ccp-input-with-prefix"
              />
            </div>

            <div className="form-section">
              <h3>Social Media Links</h3>
              <input
                type="url"
                name="instagramLink"
                value={formData.instagramLink}
                onChange={handleInputChange}
                placeholder="Instagram Profile URL"
                className="ccp-input ccp-input-with-prefix"
                required
              />
              <input
                type="url"
                name="website"
                value={formData.website}
                onChange={handleInputChange}
                placeholder="Website URL (optional)"
                className="ccp-input ccp-input-with-prefix"
              />
              <input
                type="url"
                name="tiktokLink"
                value={formData.tiktokLink}
                onChange={handleInputChange}
                placeholder="TikTok Profile URL (optional)"
                className="ccp-input ccp-input-with-prefix"
              />
              <input
                type="url"
                name="facebookLink"
                value={formData.facebookLink}
                onChange={handleInputChange}
                placeholder="Facebook Profile URL (optional)"
                className="ccp-input ccp-input-with-prefix"
              />
              <input
                type="url"
                name="xLink"
                value={formData.xLink}
                onChange={handleInputChange}
                placeholder="X (Twitter) Profile URL (optional)"
                className="ccp-input ccp-input-with-prefix"
              />
            </div>

            <div className="form-section">
              <h3>Affiliate Links</h3>
              <div style={{ paddingBottom: "10px", fontSize: "14px" }}>
                Create a partner or affiliate profile on each of the websites
                listed below to generate your links. Once added to your account,
                these links will be displayed across lowpricetravels.com on the
                days you select below. The earnings from these programs will be
                credited directly to your respective individual accounts, not
                through lowpricetravels.com.
                <br />
                <ul>
                  <li>
                    <a
                      href="https://www.travelpayouts.com/en/offers/aviasales-affiliate-program/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Aviasales Affiliate Program
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.travelpayouts.com/en/offers/bookingcom-affiliate-program/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Booking.com Affiliate Program
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.travelpayouts.com/en/offers/getyourguide-affiliate-program/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      GetYourGuide Affiliate Program
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <span
                  style={{
                    color: "#919191",
                    fontSize: "12px",
                  }}
                >
                  Ex. https://aviasales.tp.st/zGYVXqhz
                </span>

                <input
                  type="url"
                  name="aviasalesLink"
                  value={formData.aviasalesLink}
                  onChange={handleInputChange}
                  placeholder="Aviasales Affiliate Link"
                  className="ccp-input ccp-input-with-prefix"
                  required
                />
              </div>

              <div>
                <span
                  style={{
                    color: "#919191",
                    fontSize: "12px",
                  }}
                >
                  Ex. https://www.booking.com/index.html?aid=8084301
                </span>

                <input
                  type="url"
                  name="bookingLink"
                  value={formData.bookingLink}
                  onChange={handleInputChange}
                  placeholder="Booking.com Affiliate Link"
                  className="ccp-input ccp-input-with-prefix"
                  required
                />
              </div>
              <div>
                <span
                  style={{
                    color: "#919191",
                    fontSize: "12px",
                  }}
                >
                  Ex.
                  https://www.getyourguide.com?partner_id=M6ML54R&cmp=share_to_earn
                </span>

                <input
                  type="url"
                  name="getyourguideLink"
                  value={formData.getyourguideLink}
                  onChange={handleInputChange}
                  placeholder="GetYourGuide Affiliate Link"
                  className="ccp-input ccp-input-with-prefix"
                  required
                />
              </div>
            </div>

            <div className="form-section">
              <h3>Profile Images</h3>
              <div className="file-input">
                <label htmlFor="profileImage">Profile Image:</label>
                <input
                  type="file"
                  id="profileImage"
                  accept="image/*"
                  onChange={(e) => handleImageUpload(e, "profile")}
                  required
                />
                {formData.profileImage && (
                  <p>Profile image selected: {formData.profileImage.name}</p>
                )}
              </div>
              <div className="file-input">
                <label htmlFor="featuredImages">
                  Featured Images (select 3):
                </label>
                <input
                  type="file"
                  id="featuredImages"
                  accept="image/*"
                  multiple
                  onChange={(e) => handleImageUpload(e, "featured")}
                  required
                />
                {formData.featuredImages.length > 0 && (
                  <p>{formData.featuredImages.length} of 3 images selected</p>
                )}
                {formData.featuredImages.map((file, index) => (
                  <p key={index}>{file.name}</p>
                ))}
              </div>
            </div>

            <div className="form-section">
              <h3>Display Schedule</h3>
              <p>Select the days you would like to display your links:</p>
              <Calendar
                localizer={localizer}
                events={calendarEvents}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 400 }}
                selectable
                onSelectSlot={handleDateSelect}
                eventPropGetter={eventStyleGetter}
                dayPropGetter={(date) => {
                  if (!isDateSelectable(date)) {
                    return {
                      className: "unavailable-date",
                      style: {
                        backgroundColor: "#f0f0f0",
                        cursor: "not-allowed",
                      },
                    };
                  }
                }}
              />
              <div className="total-cost">
                <p>
                  Total Cost: <span className="cost-amount">${totalCost}</span>
                  {formData.selectedDates.length > 0 &&
                    ` (${formData.selectedDates.length} days selected)`}
                </p>
              </div>
            </div>

            <button type="submit" className="submit-button">
              Proceed to Payment
            </button>

            {progress > 0 && <p>Uploading: {progress}%</p>}
          </form>
        </div>
      ) : (
        <Elements stripe={stripePromise}>
          <div
            ref={showcaseRef}
            style={{ maxWidth: "90%", margin: "0 auto", position: "relative" }}
          >
            <InfluencerShowcase
              influencer={{
                instagramHandle: formData.instagramHandle,
                creatorName: formData.creatorName,
                tiktokHandle: formData.tiktokHandle,
                bio: formData.bio,
                website: formData.website,
                instagramLink: formData.instagramLink,
                tiktokLink: formData.tiktokLink,
                facebookLink: formData.facebookLink,
                xLink: formData.xLink,
                profileImage: profileImageTempUrl,
                featuredImages: featuredImageTempUrls,
              }}
            />
          </div>
          <div className="content-creator-signup">
            <h2>Review and Payment</h2>
            <button onClick={handleBackToForm} className="payment-back-button">
              <ArrowLeft size={20} />
              Back to Form
            </button>
            <div className="review-info">
              <h3>Your Information</h3>
              <p>
                <strong>Aviasales Link:</strong> {formData.aviasalesLink}
              </p>
              <p>
                <strong>Booking.com Link:</strong> {formData.bookingLink}
              </p>
              <p>
                <strong>GetYourGuide Link:</strong> {formData.getyourguideLink}
              </p>
              <div>
                <strong>Selected Dates:</strong>
                <ul>
                  {formData.selectedDates.map((date, index) => (
                    <li key={index}>{moment(date).format("MM/DD/YYYY")}</li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="review-notice">
              <h3>Important: Please Review Your Information</h3>
              <p>
                Before proceeding with payment, please review your profile
                preview above. Make sure everything is accurate and complete.
              </p>
              <p>
                <strong>Please note:</strong>
              </p>
              <ul>
                <li>
                  Once you complete the payment, you will not be able to change
                  your information.
                </li>
                <li>
                  No refunds will be issued after your signup is processed.
                </li>
                <li>
                  Your selected dates and all provided information will be
                  final.
                </li>
                <li>
                  If you run into any issues, please send inquiries to
                  <strong> cooper@tintechsolutions.com</strong>.
                </li>
              </ul>
              <p>
                If you need to make any changes, please use the 'Back to Form'
                button to return to the signup form.
              </p>
            </div>
            <StripePaymentForm
              amount={totalCost}
              onPaymentSuccess={handlePaymentSuccess}
            />
          </div>
        </Elements>
      )}
      ;
    </>
  );
};

export default ContentCreatorSignup;
