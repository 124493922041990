import React, { useState } from "react";
import openAiToken from "../constants/apiKeys";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BounceLoader } from "react-spinners";
import Modal from "react-modal";
import DOMPurify from "dompurify";
import { useUserLinks } from "./userLinksProvider";
import Spinner from "./spinner";

Modal.setAppElement("#root");

const CityTripPreferences = () => {
  const [destination, setDestination] = useState("");
  const [days, setDays] = useState(1);
  const [month, setMonth] = useState("January");
  const [schedulePackedness, setSchedulePackedness] = useState("relaxed");
  const [budget, setBudget] = useState("economy");
  const [prompt, setPrompt] = useState("");
  const [itinerary, setItinerary] = useState("");
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [interests, setInterests] = useState({
    food: false,
    sports: false,
    hikes: false,
    outdoors: false,
    history: false,
    culture: false,
    relaxation: false,
    nightlife: false,
    shopping: false,
  });

  const { userLinks, isLoading } = useUserLinks();

  const handleInterestChange = (interest) => {
    setInterests({ ...interests, [interest]: !interests[interest] });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setIsModalOpen(true);
    const itineraryPrompt = generateItineraryPrompt({
      destination,
      days,
      month,
      schedulePackedness,
      budget,
      interests,
    });
    setPrompt(itineraryPrompt);
    fetchOpenAIResponse(itineraryPrompt);
  };

  const generateItineraryPrompt = ({
    destination,
    days,
    month,
    schedulePackedness,
    budget,
    interests,
  }) => {
    const interestList = Object.entries(interests)
      .filter(([key, value]) => value)
      .map(([key]) => key.charAt(0).toUpperCase() + key.slice(1))
      .join(", ");

    const schedulePackednessDescription = {
      relaxed: "a relaxed schedule with more free time",
      moderate: "a moderate schedule balancing attractions and leisure",
      packed: "a packed schedule with lots of activities",
    };

    const budgetDescription = {
      economy: "an economy budget with cost-effective options",
      comfort: "a comfortable budget with some indulgences",
      luxury: "a luxurious experience with high-end options",
    };

    return `Create a ${days}-day travel itinerary for ${destination} in ${month}. I would like ${schedulePackednessDescription[schedulePackedness]}. My budget is ${budgetDescription[budget]}. I am interested in ${interestList}. 

    Please follow these guidelines:
    1. Include a variety of activities, dining options, and accommodation recommendations that fit within the specified criteria.
    2. Add emojis to make the itinerary more appealing and easier to read.
    3. Provide addresses and additional information for all places mentioned.
    4. Organize the itinerary by day, clearly labeling each day (e.g., "Day 1", "Day 2", etc.).
    5. For each day, include morning, afternoon, and evening activities or recommendations.
    
    Important formatting instructions:
    1. Do not use asterisks (**) or any other special characters for emphasis. Use plain text for all descriptions.
    2. When mentioning hotels or accommodations, use this format: [[BOOKING_LINK:name of hotel or accommodation]]
       Example: "Stay at the [[BOOKING_LINK:Grand City Hotel]]"
    3. When recommending guided tours or activities, use this format: [[GETYOURGUIDE_LINK:name of tour or activity]]
       Example: "In the afternoon, [[GETYOURGUIDE_LINK:take a walking tour of the historic district]]"
    
    Additional notes:
    - Suggest a mix of popular tourist attractions and off-the-beaten-path experiences.
    - Include recommendations for local cuisine and dining experiences.
    - If applicable, mention any seasonal events or festivals happening during the specified month.
    - Provide tips for using public transportation or getting around the city.
    
    Please ensure these formatting instructions and guidelines are followed consistently throughout the itinerary. The goal is to create a comprehensive, engaging, and easy-to-follow travel plan that caters to the specified interests and budget.`;
  };

  const fetchOpenAIResponse = async (prompt) => {
    const apiBody = {
      model: "gpt-3.5-turbo",
      messages: [{ role: "user", content: prompt }],
      max_tokens: 4000,
    };

    try {
      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${openAiToken}`,
          },
          body: JSON.stringify(apiBody),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const responseData = await response.json();
      let itineraryContent = responseData.choices[0].message.content;

      // Remove asterisks
      itineraryContent = itineraryContent.replace(/\*\*/g, "");

      // Replace custom link formats with actual HTML links
      itineraryContent = itineraryContent.replace(
        /\[\[BOOKING_LINK:(.+?)\]\]/g,
        `<a href="${userLinks.bookingLink}" target="_blank" rel="noopener noreferrer">$1</a>`
      );
      itineraryContent = itineraryContent.replace(
        /\[\[GETYOURGUIDE_LINK:(.+?)\]\]/g,
        `<a href="${userLinks.getyourguideLink}" target="_blank" rel="noopener noreferrer">$1</a>`
      );

      // Sanitize the HTML
      const sanitizedItinerary = DOMPurify.sanitize(itineraryContent);

      setItinerary(sanitizedItinerary);
    } catch (error) {
      console.error("There was an error!", error);
    } finally {
      setLoading(false);
      setIsModalOpen(false);
    }
  };

  const copyToClipboard = () => {
    // Create a temporary element
    const tempElement = document.createElement("div");
    tempElement.innerHTML = itinerary;

    // Remove all <a> tags
    const links = tempElement.getElementsByTagName("a");
    while (links[0]) {
      links[0].parentNode.insertBefore(links[0].firstChild, links[0]);
      links[0].parentNode.removeChild(links[0]);
    }

    // Get the text content
    let textToCopy = tempElement.textContent || tempElement.innerText;

    // Add the booking links at the end
    textToCopy += "\n\nUseful booking links:\n";
    textToCopy += `Hotels & Rentals: ${userLinks.bookingLink}\n`;
    textToCopy += `Attractions & Guides: ${userLinks.getyourguideLink}`;

    navigator.clipboard.writeText(textToCopy).then(
      () => {
        toast.success("Itinerary copied to clipboard!");
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  if (isLoading) return <Spinner />;

  return !prompt ? (
    <div className="trip-preferences">
      <h2 className="trip-preferences__title">Customize Your City Trip</h2>
      <form onSubmit={handleSubmit} className="trip-preferences__form">
        <div className="trip-preferences__section">
          <label htmlFor="destination" className="trip-preferences__label">
            Where are you travelling?
          </label>
          <input
            type="text"
            id="destination"
            value={destination}
            onChange={(e) => setDestination(e.target.value)}
            className="trip-preferences__input"
          />
        </div>

        <div className="trip-preferences__section">
          <label htmlFor="travelDays" className="trip-preferences__label">
            How many days are you staying?
          </label>
          <select
            id="travelDays"
            value={days}
            onChange={(e) => setDays(Number(e.target.value))}
            className="trip-preferences__select"
          >
            {[...Array(10).keys()].map((day) => (
              <option key={day} value={day + 1}>
                {day + 1}
              </option>
            ))}
          </select>
        </div>

        <div className="trip-preferences__section">
          <label htmlFor="month" className="trip-preferences__label">
            What month are you travelling?
          </label>
          <select
            id="month"
            value={month}
            onChange={(e) => setMonth(e.target.value)}
            className="trip-preferences__select"
          >
            {[
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December",
            ].map((m) => (
              <option key={m} value={m}>
                {m}
              </option>
            ))}
          </select>
        </div>

        <div className="trip-preferences__section">
          <label
            htmlFor="schedulePackedness"
            className="trip-preferences__label"
          >
            How packed do you like your schedule?
          </label>
          <select
            id="schedulePackedness"
            value={schedulePackedness}
            onChange={(e) => setSchedulePackedness(e.target.value)}
            className="trip-preferences__select"
          >
            <option value="relaxed">Relaxed</option>
            <option value="moderate">Moderate</option>
            <option value="packed">Packed</option>
          </select>
        </div>

        <div className="trip-preferences__section">
          <label htmlFor="budget" className="trip-preferences__label">
            What is your budget?
          </label>
          <select
            id="budget"
            value={budget}
            onChange={(e) => setBudget(e.target.value)}
            className="trip-preferences__select"
          >
            <option value="economy">Economy</option>
            <option value="comfort">Comfort</option>
            <option value="luxury">Luxury</option>
          </select>
        </div>

        <fieldset className="trip-preferences__fieldset">
          <legend className="trip-preferences__legend">Interests:</legend>
          {Object.keys(interests).map((interest) => (
            <div key={interest} className="trip-preferences__checkbox">
              <input
                type="checkbox"
                id={interest}
                checked={interests[interest]}
                onChange={() => handleInterestChange(interest)}
              />
              <label
                htmlFor={interest}
                className="trip-preferences__checkbox-label"
              >
                {interest.charAt(0).toUpperCase() + interest.slice(1)}
              </label>
            </div>
          ))}
        </fieldset>
        <div style={{ textAlign: "center" }}>
          <button
            type="submit"
            disabled={!destination}
            className={`trip-preferences__button ${
              !destination ? "trip-preferences__button--disabled" : ""
            }`}
          >
            Generate Itinerary
          </button>
        </div>
      </form>
    </div>
  ) : (
    <div className="thank-you">
      <main className="thank-you__main">
        <h1 className="thank-you__title">
          Thank You for Choosing LowPriceTravels!
        </h1>
        <h3 className="thank-you__subtitle">
          Here is your travel plan for your trip to {destination}. Our AI used
          your interests to create a specialized itinerary for you. Book your
          trip now!
        </h3>
        <div className="thank-you__links">
          <h3 className="thank-you__subtitle">
            Find deals and save on your trip:
          </h3>
          <a
            href={userLinks.bookingLink}
            target="_blank"
            rel="noopener noreferrer"
            className="thank-you__link thank-you__link--booking"
          >
            Hotels & Rentals at Booking.com
          </a>

          <a
            href={userLinks.getyourguideLink}
            target="_blank"
            rel="noopener noreferrer"
            className="thank-you__link thank-you__link--getyourguide"
          >
            Attractions & Guides at GetYourGuide.com
          </a>
        </div>
      </main>
      <div className="itinerary">
        {loading ? (
          <Modal
            isOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}
            className="modal"
            overlayClassName="modal-overlay"
            contentLabel="Loading Itinerary"
          >
            <div className="modal__content">
              <BounceLoader color="#4C959F" loading={loading} size={60} />
              <p className="modal__text">
                Generating your personalized itinerary...
              </p>
            </div>
          </Modal>
        ) : (
          <>
            <div className="itinerary__content">
              <div dangerouslySetInnerHTML={{ __html: itinerary }} />
            </div>
            <button
              className="itinerary__button itinerary__button--copy"
              onClick={copyToClipboard}
            >
              Copy Itinerary
            </button>
            <button
              className="itinerary__button"
              onClick={() => window.open(userLinks.aviasalesLink, "_blank")}
            >
              Book Trip
            </button>
          </>
        )}
      </div>
      <ToastContainer position="bottom-left" autoClose={2000} />
    </div>
  );
};

export default CityTripPreferences;
