import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { ChevronLeft, ChevronRight } from "lucide-react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InfluencerShowcase from "./influencerShowcase";

const Arrow = ({ direction, onClick }) => (
  <div
    onClick={onClick}
    style={{
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      cursor: "pointer",
      zIndex: 1,
      background: "rgba(255, 255, 255, 0.7)",
      borderRadius: "50%",
      padding: "8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      [direction === "next" ? "right" : "left"]: "10px",
    }}
  >
    {direction === "next" ? (
      <ChevronRight size={24} color="#333" />
    ) : (
      <ChevronLeft size={24} color="#333" />
    )}
  </div>
);

const InfluencerCarousel = ({ influencers }) => {
  const [randomizedInfluencers, setRandomizedInfluencers] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const shuffled = [...influencers].sort(() => Math.random() - 0.5);
    setRandomizedInfluencers(shuffled);

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [influencers]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    nextArrow: <Arrow direction="next" />,
    prevArrow: <Arrow direction="prev" />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  const carouselStyle = {
    maxWidth: "100%",
    margin: "0 auto",
    position: "relative",
    padding: isMobile ? "0 20px" : "0 50px",
  };

  return (
    <div style={carouselStyle}>
      <Slider {...settings}>
        {randomizedInfluencers.map((influencer, index) => (
          <div key={index} style={{ padding: isMobile ? "10px 0" : "20px 0" }}>
            <InfluencerShowcase influencer={influencer} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default InfluencerCarousel;
