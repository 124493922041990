import React, { useEffect, useState } from "react";
import ContentCreatorSignup from "./contentCreatorSignup";
import getUsers from "./getUsers";
import InfluencerCarousel from "./influencerCarousel";
import { useLocation } from "react-router-dom";
import Spinner from "./spinner";

const ContentCreatorPage = () => {
  const [showSignupForm, setShowSignupForm] = useState(false);
  const [users, setUsers] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      const data = await getUsers();
      setUsers(data);
    };

    fetchData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const renderCreatorStep = (title, description) => (
    <div className="ccp-creator-step-card">
      <h3 className="ccp-creator-step-title">{title}</h3>
      <p className="ccp-creator-step-text">{description}</p>
    </div>
  );

  const renderMarketingSection = () => (
    <section className="ccp-marketing-section">
      <h2 className="ccp-section-title">Showcase Your Content</h2>
      <h3 className="ccp-section-subtitle">
        Expand your audience and earn through affiliate programs
      </h3>
      <div className="ccp-creator-steps-container">
        {renderCreatorStep(
          "Step 1: Join Travel Affiliate Programs",
          "Sign up for affiliate programs with our partners: Booking.com, Aviasales.com, and GetYourGuide.com. Obtain your unique links from each platform. You'll need these to complete your LowPriceTravels profile and start earning."
        )}
        {renderCreatorStep(
          "Step 2: Create Your LowPriceTravels Profile",
          "Click 'Sign Up Now' below to create your profile. Enter your affiliate links and social media details. Your profile will appear in our affiliate carousel for 30 days, and your links will appear throughout the lowpricetravels.com website on the days you schedule."
        )}
        {renderCreatorStep(
          "Step 3: Grow Your Audience and Earn",
          "Screenshot your schedule to save it. On your scheduled days, your links are prominently displayed on the flight search and itinerary pages of the website. Direct your followers to lowpricetravels.com and you'll earn a percentage when they book through your links!"
        )}
      </div>
    </section>
  );

  // Main return with conditional rendering for signup form and influencer showcase
  return (
    <div className="content-creator-signup-wrapper">
      {renderMarketingSection()}

      {!showSignupForm && (
        <section className="ccp-signup-button-section">
          <button
            className="ccp-signup-button"
            onClick={() => setShowSignupForm(true)}
          >
            Sign Up Now!
          </button>
        </section>
      )}

      {/* Conditional rendering: Show signup form or influencer showcase */}
      {showSignupForm ? (
        <ContentCreatorSignup />
      ) : (
        <section style={{ paddingBottom: "50px" }}>
          {users.length > 0 ? (
            <InfluencerCarousel influencers={users} />
          ) : (
            <Spinner />
          )}
        </section>
      )}
    </div>
  );
};

export default ContentCreatorPage;
