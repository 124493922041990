import { getFirestore, collection, getDocs } from "firebase/firestore";

const getUsers = async () => {
  const db = getFirestore();
  const usersCollection = collection(db, "users"); // Assuming 'users' is your collection name
  const snapshot = await getDocs(usersCollection);

  return snapshot.docs.map((doc) => ({
    id: doc.id,
    creatorName: doc.data().creatorName,
    instagramHandle: doc.data().instagramHandle,
    tiktokHandle: doc.data().tiktokHandle,
    bio: doc.data().bio,
    profileImage: doc.data().profileImage, // Avatar URL
    featuredImages: doc.data().featuredImages || [], // Array of featured images
    website: doc.data().website || null,
    instagramLink: doc.data().instagramLink || null,
    tiktokLink: doc.data().tiktokLink || null,
    facebookLink: doc.data().facebookLink || null,
    xLink: doc.data().xLink || null, // Twitter/X
    aviasalesLink: doc.data().aviasalesLink || null,
    bookingLink: doc.data().bookingLink || null,
    getyourguideLink: doc.data().getyourguideLink || null,
    createdAt: doc.data().createdAt || null,
    displayDays: doc.data().displayDays || null,
    displayMonth: doc.data().displayMonth || null,
  }));
};

export default getUsers;
