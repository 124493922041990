import React, { createContext, useContext, useState, useEffect } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "./db"; // Adjust this import based on your Firebase setup

// Create the context
const UserLinksContext = createContext();

// Default links
const DEFAULT_LINKS = {
  aviasalesLink: "https://aviasales.tp.st/zGYVXqhz",
  bookingLink: "https://www.booking.com/index.html?aid=8084301",
  getyourguideLink:
    "https://www.getyourguide.com?partner_id=M6ML54R&cmp=share_to_earn",
};

export const UserLinksProvider = ({ children }) => {
  const [userLinks, setUserLinks] = useState(DEFAULT_LINKS);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUserLinks = async () => {
      setIsLoading(true);
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Set to start of day

      const usersRef = collection(db, "users");
      const q = query(
        usersRef,
        where("selectedDates", "array-contains", today)
      );

      try {
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const userDoc = querySnapshot.docs[0];
          const userData = userDoc.data();
          setUserLinks({
            aviasalesLink:
              userData.aviasalesLink || DEFAULT_LINKS.aviasalesLink,
            bookingLink: userData.bookingLink || DEFAULT_LINKS.bookingLink,
            getyourguideLink:
              userData.getyourguideLink || DEFAULT_LINKS.getyourguideLink,
          });
        } else {
          setUserLinks(DEFAULT_LINKS);
        }
      } catch (error) {
        console.error("Error fetching user links:", error);
        setUserLinks(DEFAULT_LINKS);
      }

      setIsLoading(false);
    };

    fetchUserLinks();
  }, []);

  return (
    <UserLinksContext.Provider value={{ userLinks, isLoading }}>
      {children}
    </UserLinksContext.Provider>
  );
};

// Custom hook to use the context
export const useUserLinks = () => {
  const context = useContext(UserLinksContext);
  if (context === undefined) {
    throw new Error("useUserLinks must be used within a UserLinksProvider");
  }
  return context;
};
