import React, { useState, useEffect } from "react";
import Select from "react-select";
import Modal from "react-modal";
import { BounceLoader } from "react-spinners";

Modal.setAppElement("#root");

const FlightSearch = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [clickCount, setClickCount] = useState(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const modalContent = [
    "Scraping Google Flights...",
    "Scraping Delta Airlines...",
    "Scraping United Airlines...",
    "Scraping American Airlines...",
    "Scraping Southwest Airlines...",
    "Scraping Frontier Airlines...",
  ];
  const [currentContentIndex, setCurrentContentIndex] = useState(0);

  const locations = [
    { value: "Albuquerque, New Mexico", label: "Albuquerque, New Mexico" },
    { value: "Anchorage, Alaska", label: "Anchorage, Alaska" },
    { value: "Atlanta, Georgia", label: "Atlanta, Georgia" },
    { value: "Baltimore, Maryland", label: "Baltimore, Maryland" },
    { value: "Barcelona, Spain", label: "Barcelona, Spain" },
    { value: "Bangkok, Thailand", label: "Bangkok, Thailand" },
    { value: "Boston, Massachusetts", label: "Boston, Massachusetts" },
    { value: "Burbank, California", label: "Burbank, California" },
    { value: "Cancún, Mexico", label: "Cancún, Mexico" },
    { value: "Cape Town, South Africa", label: "Cape Town, South Africa" },
    { value: "Charlotte, North Carolina", label: "Charlotte, North Carolina" },
    { value: "Chicago, Illinois", label: "Chicago, Illinois" },
    { value: "Cincinnati, Ohio", label: "Cincinnati, Ohio" },
    { value: "Cleveland, Ohio", label: "Cleveland, Ohio" },
    { value: "Columbus, Ohio", label: "Columbus, Ohio" },
    { value: "Dallas, Texas", label: "Dallas, Texas" },
    { value: "Dayton, Ohio", label: "Dayton, Ohio" },
    { value: "Denver, Colorado", label: "Denver, Colorado" },
    { value: "Detroit, Michigan", label: "Detroit, Michigan" },
    {
      value: "Dubai, United Arab Emirates",
      label: "Dubai, United Arab Emirates",
    },
    { value: "Dublin, Ireland", label: "Dublin, Ireland" },
    { value: "Fort Lauderdale, Florida", label: "Fort Lauderdale, Florida" },
    { value: "Frankfurt, Germany", label: "Frankfurt, Germany" },
    { value: "Honolulu, Hawaii", label: "Honolulu, Hawaii" },
    { value: "Houston, Texas", label: "Houston, Texas" },
    { value: "Indianapolis, Indiana", label: "Indianapolis, Indiana" },
    { value: "Kansas City, Missouri", label: "Kansas City, Missouri" },
    { value: "Las Vegas, Nevada", label: "Las Vegas, Nevada" },
    { value: "Little Rock, Arkansas", label: "Little Rock, Arkansas" },
    { value: "London, United Kingdom", label: "London, United Kingdom" },
    { value: "Long Beach, California", label: "Long Beach, California" },
    { value: "Los Angeles, California", label: "Los Angeles, California" },
    { value: "Madrid, Spain", label: "Madrid, Spain" },
    { value: "Marrakesh, Morocco", label: "Marrakesh, Morocco" },
    { value: "Miami, Florida", label: "Miami, Florida" },
    { value: "Mexico City, Mexico", label: "Mexico City, Mexico" },
    { value: "Minneapolis, Minnesota", label: "Minneapolis, Minnesota" },
    { value: "Munich, Germany", label: "Munich, Germany" },
    { value: "Nashville, Tennessee", label: "Nashville, Tennessee" },
    { value: "New Orleans, Louisiana", label: "New Orleans, Louisiana" },
    { value: "New York City", label: "New York City" },
    { value: "Newark, New Jersey", label: "Newark, New Jersey" },
    { value: "Oakland, California", label: "Oakland, California" },
    { value: "Oklahoma City, Oklahoma", label: "Oklahoma City, Oklahoma" },
    { value: "Ontario, California", label: "Ontario, California" },
    { value: "Orlando, Florida", label: "Orlando, Florida" },
    { value: "Paris, France", label: "Paris, France" },
    {
      value: "Philadelphia, Pennsylvania",
      label: "Philadelphia, Pennsylvania",
    },
    { value: "Phoenix, Arizona", label: "Phoenix, Arizona" },
    { value: "Pittsburgh, Pennsylvania", label: "Pittsburgh, Pennsylvania" },
    { value: "Provo, Utah", label: "Provo, Utah" },
    {
      value: "Punta Cana, Dominican Republic",
      label: "Punta Cana, Dominican Republic",
    },
    { value: "Raleigh, North Carolina", label: "Raleigh, North Carolina" },
    { value: "Rome, Italy", label: "Rome, Italy" },
    { value: "Sacramento, California", label: "Sacramento, California" },
    { value: "Salt Lake City, Utah", label: "Salt Lake City, Utah" },
    { value: "San Antonio, Texas", label: "San Antonio, Texas" },
    { value: "San Diego, California", label: "San Diego, California" },
    { value: "San Francisco, California", label: "San Francisco, California" },
    { value: "San Jose, California", label: "San Jose, California" },
    { value: "Seattle, Washington", label: "Seattle, Washington" },
    { value: "Seoul, South Korea", label: "Seoul, South Korea" },
    { value: "Singapore", label: "Singapore" },
    { value: "Spokane, Washington", label: "Spokane, Washington" },
    { value: "St. Louis, Missouri", label: "St. Louis, Missouri" },
    { value: "Sydney, Australia", label: "Sydney, Australia" },
    { value: "Syracuse, New York", label: "Syracuse, New York" },
    { value: "Tampa, Florida", label: "Tampa, Florida" },
    { value: "Tokyo, Japan", label: "Tokyo, Japan" },
    { value: "Toronto, Canada", label: "Toronto, Canada" },
    { value: "Tucson, Arizona", label: "Tucson, Arizona" },
    { value: "Vienna, Austria", label: "Vienna, Austria" },
    { value: "Washington, D.C.", label: "Washington, D.C." },
    { value: "Zurich, Switzerland", label: "Zurich, Switzerland" },
  ];

  const generateMonths = () => {
    const currentDate = new Date();
    const months = [];
    for (let i = 0; i < 6; i++) {
      const futureDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + i,
        1
      );
      const monthYear = futureDate.toLocaleString("default", {
        month: "long",
        year: "numeric",
      });
      months.push({ value: monthYear, label: monthYear });
    }
    return months;
  };

  const months = generateMonths();

  const fetchFlights = async () => {
    setIsLoading(true);
    setIsModalOpen(true);
    // const domain = "http://127.0.0.1:5000";
    const domain = "https://shielded-journey-89926-a1d028117509.herokuapp.com";
    const url = `${domain}/scrape/google-flights?origin_airport=${props.departureLocation}&destination_airport=${props.arrivalLocation}&month_year=${props.selectedMonth}`;
    try {
      const response = await fetch(url, { mode: "cors" });
      props.setNoResultsError(false);
      if (response.ok) {
        const data = await response.json();
        if (data.length) {
          props.setSearchDetails(data);
        } else {
          props.setNoResultsError(true);
        }
      } else {
        console.error(
          "Error fetching flights:",
          response.status,
          response.statusText
        );
        props.setNoResultsError(true);
      }
    } catch (error) {
      // Handle network errors or other exceptions
      console.error("Error fetching flights:", error);
      props.setNoResultsError(true);
    } finally {
      setIsLoading(false);
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (isModalOpen && modalContent.length > 0) {
      const timer = setTimeout(() => {
        setCurrentContentIndex((prevIndex) =>
          prevIndex === modalContent.length - 1 ? 0 : prevIndex + 1
        );
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [isModalOpen, modalContent, currentContentIndex]);

  const handleHeadingClick = () => {
    setClickCount((prevCount) => prevCount + 1);
    if (clickCount + 1 === 5) {
      props.setAdmin(true);
    }
  };

  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "4px",
      border: "1px solid #ddd",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #FE737B",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#FE737B" : "white",
      color: state.isSelected ? "white" : "black",
      "&:hover": {
        backgroundColor: "#FFD1D1",
      },
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "4px",
      overflow: "hidden",
      width: "100%",
    }),
  };

  return (
    <div className="flightSearchContainer">
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            backdropFilter: "blur(5px)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#f9f9f9",
            width: screenWidth > 600 ? "500px" : "90vw",
            maxHeight: "80%",
            padding: "20px",
            borderRadius: "10px",
            border: "none",
            outline: "none",
          },
        }}
      >
        <div className="modal-content">
          <BounceLoader color="#4C959F" loading={isLoading} size={60} />
          <h3 className="modal-title">{modalContent[currentContentIndex]}</h3>
          <p className="modal-text">
            Scraping should take around 10-20 seconds to complete
          </p>
        </div>
      </Modal>
      <div className="white-box" onClick={handleHeadingClick}>
        <h1>Travel on a budget with AI</h1>
        <h4>Explore Affordable Flights Nationwide and Worldwide</h4>
      </div>
      {!isLoading && (
        <div className="fields">
          <div className="searchFields">
            <Select
              className="select"
              value={locations.find(
                (location) => location.value === props.departureLocation
              )}
              onChange={(selectedOption) =>
                props.setDepartureLocation(
                  selectedOption ? selectedOption.value : ""
                )
              }
              options={locations}
              placeholder="Select Departure City"
              isClearable={true}
              styles={customSelectStyles}
            />
            <Select
              className="select"
              value={locations.find(
                (location) => location.value === props.arrivalLocation
              )}
              onChange={(selectedOption) =>
                props.setArrivalLocation(
                  selectedOption ? selectedOption.value : ""
                )
              }
              options={locations}
              placeholder="Select Arrival City"
              isClearable={true}
              styles={customSelectStyles}
            />
            <Select
              className="select"
              value={months.find(
                (month) => month.value === props.selectedMonth
              )}
              onChange={(selectedOption) =>
                props.setSelectedMonth(
                  selectedOption ? selectedOption.value : ""
                )
              }
              options={months}
              placeholder="Select Month To Travel"
              isClearable={true}
              styles={customSelectStyles}
            />
          </div>
          <button
            className={`searchButton ${
              !props.selectedMonth ||
              !props.arrivalLocation ||
              !props.departureLocation
                ? "disabled"
                : ""
            }`}
            onClick={fetchFlights}
            disabled={
              !props.selectedMonth ||
              !props.arrivalLocation ||
              !props.departureLocation
            }
          >
            Search Flights
          </button>
        </div>
      )}
    </div>
  );
};

export default FlightSearch;
