import React from "react";

const InfluencerShowcase = ({ influencer }) => {
  const socialLinks = [
    {
      name: "Website",
      url: influencer.website,
      color: "#FFFFFF",
      textColor: "#59a7c4",
    },
    {
      name: "Instagram",
      url: influencer.instagramLink,
      color: "#E1306C",
      textColor: "#FFFFFF",
    },
    {
      name: "TikTok",
      url: influencer.tiktokLink,
      color: "#000000",
      textColor: "#FFFFFF",
    },
    {
      name: "Facebook",
      url: influencer.facebookLink,
      color: "#4267B2",
      textColor: "#FFFFFF",
    },
    {
      name: "X",
      url: influencer.xLink,
      color: "#1DA1F2",
      textColor: "#FFFFFF",
    },
  ].filter((link) => link.url);

  return (
    <div className="is-container">
      <div className="is-header">
        <img
          src={influencer.profileImage || "/path/to/placeholder-image.jpg"}
          alt={influencer.creatorName}
          className="is-avatar"
        />
        <div>
          <h2 className="is-name">{influencer.creatorName}</h2>
          <div className="is-handles">
            {influencer.instagramHandle && (
              <span className="is-handle">
                Instagram: @{influencer.instagramHandle}
              </span>
            )}
            {influencer.tiktokHandle && (
              <span className="is-handle">
                TikTok: @{influencer.tiktokHandle}
              </span>
            )}
          </div>
          <p className="is-bio">{influencer.bio}</p>
        </div>
      </div>
      <div className="is-photo-grid">
        {influencer.featuredImages.slice(0, 3).map((photo, index) => (
          <div key={index} className="is-photo">
            <img
              src={photo || "/path/to/placeholder-image.jpg"}
              alt={`${influencer.creatorName}'s content ${index + 1}`}
            />
          </div>
        ))}
      </div>
      <div className="is-link-container">
        {socialLinks.map((link, index) => (
          <a
            key={index}
            href={link.url}
            target="_blank"
            rel="noopener noreferrer"
            className="is-link"
            style={{
              backgroundColor: link.color,
              color: link.textColor,
            }}
          >
            {link.name}
          </a>
        ))}
      </div>
    </div>
  );
};

export default InfluencerShowcase;
