import React from "react";
import Navbar from "./navigation/navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./components/homePage";
import Footer from "./navigation/footer";
import SelectTrip from "./components/selectTrip";
import ContentCreatorSignup from "./components/contentCreatorPage";
import { UserLinksProvider } from "./components/userLinksProvider";

const App = () => {
  return (
    <UserLinksProvider>
      <Router>
        <div className="app-container">
          <Navbar />
          <div className="body-container">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/create-itinerary" element={<SelectTrip />} />
              <Route
                path="/content-creator"
                element={<ContentCreatorSignup />}
              />
            </Routes>
          </div>
          <Footer />
        </div>
      </Router>
    </UserLinksProvider>
  );
};

export default App;
