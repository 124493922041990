import React, { useEffect, useState } from "react";
import {
  Calendar,
  TrendingUp,
  Hotel,
  Car,
  ArrowLeft,
  ChevronLeft,
  ChevronRight,
} from "lucide-react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CityTripPreferences from "./cityTripPreferences";
import RoadTripPreferences from "./roadTripPreferences";
import { useUserLinks } from "./userLinksProvider";

// Import additional images for the carousel
import Itinerary from "../images/itinerary.jpg";
import Austrailia from "../images/austrailia.png";
import Disneyland from "../images/disneyland.jpg";
import Greece from "../images/greece.jpg";
import Hawaii from "../images/hawaii.jpg";
import Italy from "../images/italy.jpg";
import Vegas from "../images/vegas.jpeg";
import Hammock from "../images/hammock.webp";
import Hiking from "../images/hiking2.jpg";
import Resort from "../images/resort.jpg";
import Tropical from "../images/tropical.jpg";
import AmusementPark from "../images/Whirlwind_01.jpg";
import Spinner from "./spinner";

const Arrow = ({ direction, onClick }) => (
  <div
    onClick={onClick}
    style={{
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      cursor: "pointer",
      zIndex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "rgba(255, 255, 255, 0.8)",
      borderRadius: "50%",
      padding: "12px",
      boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
      [direction === "next" ? "right" : "left"]: "10px",
    }}
  >
    {direction === "next" ? (
      <ChevronRight size={24} color="#333" />
    ) : (
      <ChevronLeft size={24} color="#333" />
    )}
  </div>
);

const SelectTrip = () => {
  const [selectedTrip, setSelectedTrip] = useState(null);
  const { userLinks, isLoading } = useUserLinks();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  const handleBackClick = () => {
    setSelectedTrip(null);
  };

  const carouselImages = [
    Itinerary,
    Austrailia,
    Disneyland,
    Greece,
    Hawaii,
    Italy,
    Vegas,
    Hammock,
    Hiking,
    Resort,
    Tropical,
    AmusementPark,
  ];

  const settings = {
    dots: true,
    infinite: !isMobile,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: !isMobile,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    nextArrow: !isMobile ? <Arrow direction="next" /> : null,
    prevArrow: !isMobile ? <Arrow direction="prev" /> : null,
  };

  if (selectedTrip === "city") {
    return (
      <div className="container">
        <button className="back-button" onClick={handleBackClick}>
          <ArrowLeft size={24} />
          Back to Selection
        </button>
        <CityTripPreferences />
      </div>
    );
  }

  if (selectedTrip === "road") {
    return (
      <div className="container">
        <button className="back-button" onClick={handleBackClick}>
          <ArrowLeft size={24} />
          Back to Selection
        </button>
        <RoadTripPreferences />
      </div>
    );
  }

  if (isLoading) return <Spinner />;

  return (
    <div
      className="container"
      style={{ maxWidth: "1200px", margin: "0 auto", padding: "40px 20px" }}
    >
      <div>
        <h1 className="title">Plan Your Perfect Trip</h1>
        <p className="subtitle">
          Let AI help you create a personalized itinerary for your next
          adventure
        </p>
      </div>

      <div
        style={{
          backgroundColor: "#59a7c4",
          padding: isMobile ? "20px 5%" : "40px 10%",
          borderRadius: "20px",
          marginTop: "10px",
          marginBottom: "20px",
        }}
      >
        <div
          className="hero-image-container"
          style={{
            margin: "auto",
            borderRadius: "15px",
          }}
        >
          <Slider {...settings}>
            {carouselImages.map((image, index) => (
              <div
                key={index}
                style={{ borderRadius: "15px", overflow: "hidden" }}
              >
                <img
                  src={image}
                  alt={`Travel adventure ${index + 1}`}
                  style={{
                    width: "100%",
                    height: isMobile ? "250px" : "400px",
                    objectFit: "cover",
                    borderRadius: "15px",
                  }}
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>

      <div className="itinerary-section-container">
        <h2 className="itinerary-section-title">Choose Your Trip Type</h2>
        <div className="itinerary-options-container">
          <div className="itinerary-option-card">
            <div className="icon">
              <Calendar size={60} color="#59a7c4" />
            </div>
            <h3 className="itinerary-option-card-title">City trip</h3>
            <p className="card-description">
              Create a tailored itinerary for exploring urban destinations
            </p>
            <button
              className="itinerary-option-card-button"
              onClick={() => setSelectedTrip("city")}
            >
              Plan City Trip
            </button>
          </div>
          <div className="itinerary-option-card itinerary-option-card-new">
            <div className="icon">
              <TrendingUp size={60} color="#59a7c4" />
            </div>
            <h3 className="itinerary-option-card-title">Road trip</h3>
            <p className="card-description">
              Design a custom route for your perfect road adventure
            </p>
            <button
              className="itinerary-option-card-button"
              onClick={() => setSelectedTrip("road")}
            >
              Plan Road Trip
            </button>
          </div>
        </div>
      </div>

      <div className="booking-section">
        <h3 className="booking-title">Ready to book?</h3>
        <div className="booking-links">
          <a
            href={userLinks.bookingLink}
            target="_blank"
            rel="noopener noreferrer"
            className="booking-link"
          >
            <Hotel size={32} className="link-icon" />
            Book Hotels
          </a>
          <a
            href={userLinks.bookingLink}
            target="_blank"
            rel="noopener noreferrer"
            className="booking-link"
          >
            <Car size={32} className="link-icon" />
            Rent a Car
          </a>
        </div>
      </div>
    </div>
  );
};

export default SelectTrip;
