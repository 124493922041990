import React from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useUserLinks } from "./userLinksProvider";
import Spinner from "./spinner";

const TripsList = (props) => {
  const TripCard = ({ trip, admin }) => {
    const { userLinks, isLoading } = useUserLinks();

    const getDatesList = () => {
      const dates = trip.dates;
      if (!dates || dates.length === 0) {
        return "";
      }
      return dates.join(" | ");
    };

    const copyText = (text) => {
      navigator.clipboard.writeText(text);
      toast.success("Content copied to clipboard", {});
    };

    const handleCopy = () => {
      const tripInfo = `${trip.month}\n${trip.price}\n${getDatesList()}`;
      copyText(tripInfo);
    };

    const handleCopyForNewsletter = () => {
      const newsletterInfo = `${trip.price} - ${getDatesList()}`;
      copyText(newsletterInfo);
    };

    if (isLoading) return <Spinner />;

    return (
      <div className="tl-trip-card">
        <div className="tl-trip-info">
          <h2 className="tl-trip-month">{trip.month}</h2>
          <p className="tl-trip-dates">{getDatesList()}</p>
          <h3 className="tl-trip-price">{trip.price}</h3>
        </div>
        <div className="tl-button-container">
          <a
            href={userLinks.aviasalesLink}
            target="_blank"
            rel="noopener noreferrer"
            className="tl-trip-button tl-book-now-button"
          >
            BOOK NOW
          </a>
          <button
            className="tl-trip-button tl-copy-button"
            onClick={handleCopy}
          >
            Copy
          </button>
          {admin && (
            <button
              className="tl-trip-button tl-copy-newsletter-button"
              onClick={handleCopyForNewsletter}
            >
              Copy for Newsletter
            </button>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="tl-trips-list-container">
      <div className="tl-scrollable-area">
        {props.flightSearchDetails.map((trip, index) => (
          <TripCard key={index} trip={trip} admin={props.admin} />
        ))}
      </div>
      <ToastContainer position="bottom-left" autoClose={2000} />
    </div>
  );
};

export default TripsList;
