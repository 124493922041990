import React from "react";
import { Link } from "react-router-dom";
import { Camera, Users, DollarSign, ArrowUp } from "lucide-react";

const CarouselSpotMarketingSection = () => {
  const renderCard = (Icon, title, description, buttonText) => (
    <div className="csms-card">
      <div className="csms-card-icon">
        <Icon size={48} />
      </div>
      <h3 className="csms-card-title">{title}</h3>
      <p className="csms-card-text">{description}</p>
      <Link to="/content-creator" className="csms-card-button">
        {buttonText}
      </Link>
    </div>
  );

  return (
    <div className="csms-marketing-section">
      <h2 className="csms-section-title">
        <ArrowUp size={40} className="csms-arrow csms-left-arrow" />
        Showcase Your Content
        <ArrowUp size={40} className="csms-arrow csms-right-arrow" />
      </h2>
      <h3 className="csms-section-subtitle">
        Secure Your Spot in Our Content Creator Section
      </h3>
      <div className="csms-cards-container">
        {renderCard(
          Camera,
          "Featured Showcase",
          "Get a prime spot in our content creator section. Showcase your content, profile, and social links to a wide audience of potential followers and collaborators.",
          "Reserve Your Spot"
        )}
        {renderCard(
          DollarSign,
          "Monetization Links",
          "Leverage our platform's monetization links throughout the website. Turn your influence into income with affiliate links all throughout lowpricetravels.com.",
          "Start Earning"
        )}
        {renderCard(
          Users,
          "Expand Your Reach",
          "Connect with our diverse community of influencers and page visitors. Benefit from cross-promotion opportunities and grow your follower base exponentially.",
          "Join Our Network"
        )}
      </div>
    </div>
  );
};

export default CarouselSpotMarketingSection;
