import React from "react";
import { Check } from "lucide-react";

const SuccessComponent = ({ selectedDates }) => {
  return (
    <div className="success-container">
      <div className="success-icon">
        <Check size={50} color="#4CAF50" />
      </div>
      <h2>Congratulations!</h2>
      <p>
        Your profile has been created successfully and your payment has been
        processed.
      </p>
      <div className="success-details">
        <p>Your links will be displayed on the following dates:</p>
        <ul>
          {selectedDates.map((date, index) => (
            <li key={index}>{new Date(date).toLocaleDateString()}</li>
          ))}
        </ul>
      </div>
      <p>Please SCREENSHOT now to save your link display dates!</p>
      <p>
        Your profile is now visible in the affiliate carousel. It will stay in
        our content creator display for 30 days.{" "}
      </p>
      <br />
      <p>
        Make sure to promote your affiliate links on the dates you've selected
        by directing your followers to lowpricetravels.com. For any questions or
        support, please contact <strong>cooper@tintechsolutions.com</strong>
      </p>
      <button
        className="return-home-button"
        onClick={() => (window.location.href = "/")}
      >
        Return to Home
      </button>
    </div>
  );
};

export default SuccessComponent;
